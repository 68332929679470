<script lang='ts' setup>
import { storeToRefs } from 'pinia'
import type { LabeledRadioOption } from '~~/types/labeled-radio'
import type { BuyEvent } from '~~/types/buy-event'
import { useRawSkuService } from '~~/composables/services/raw-sku-service'
import { MeasureType, PriceType } from '~~/types/buy-event'
import type { APIFilter } from '~~/composables/api'
import type { RawProduct } from '~~/composables/services/raw-product-service'
import type { RawSku } from '~~/types/raw-sku'

const emit = defineEmits(['collapse'])
const { shopper } = storeToRefs(useShopperStore())
const { getFeasibleRawSkusWithPackaging } = useRawSkuService()
const { addProcurementItem, addBuyEvent, listProcurementItems } = useProcurementStore()
const { referenceDate } = storeToRefs(useProcurementStore())
const { success, warning } = useNotification()
const { closeModal } = useModal()
const { isFeatureSet } = useFeatureFlag()
const { createValidation, field, z } = useValidation()

const selectedRawProduct = ref<RawProduct | null>(null)
const rawSkuData = ref<RawSku | null>(null)
const isRawSkuDataLoading = ref(false)

const { validate, values: buyEvent, errors, isValid } = createValidation<BuyEvent & { productId: string; maturationTypeId: string; qualityTypeId: string }>({
    id: field(''),
    quantity: field(1, z.number().gt(0))
        .errorMessage('Informe a quantidade comprada'),
    price: field(0, z.number().gt(0))
        .errorMessage('Informe o preço pago'),
    wholesaleQuantity: field(0, z.number().gt(0))
        .errorMessage('Informe a quantidade de atacado'),
    provider: field('', z.string().min(1))
        .errorMessage('Informe um fornecedor'),
    shopper: field(shopper.value?.id_shopper || ''),
    measureType: field(MeasureType.Box),
    priceType: field(PriceType.UnitPrice),
    productId: field('', z.string().min(1))
        .errorMessage('Informe um produto'),
    qualityTypeId: field('', z.string().nullable().refine((): Boolean => {
        const quality = selectedRawProduct.value?.product_policy?.quality_source
        return quality !== 'SOURCING' || (quality === 'SOURCING' && !!buyEvent.qualityTypeId)
    },
    )).errorMessage('Informe a qualidade do produto'),
    maturationTypeId: field('', z.string().nullable().refine((): Boolean => {
        const maturation = selectedRawProduct.value?.product_family.maturation_sensitive
        return maturation !== 'SENSITIVE' || (maturation === 'SENSITIVE' && !!buyEvent.maturationTypeId)
    },
    )).errorMessage('Informe a maturação do produto'),
    isJustInTime: field(false),
    wholesaleQuantityUnit: field(''),
})

const isLoadingProductCreation = ref(false)

const onRawProductSelected = (rawProduct: RawProduct) => {
    selectedRawProduct.value = rawProduct
    buyEvent.maturationTypeId = '84a82c3e-388c-407b-a4cc-f33d63762dac'
    buyEvent.qualityTypeId = 'be4c27e9-ef10-4c16-82f7-3ca32610aa01'
}

const isMaturationTypeUsed = computed(() => selectedRawProduct.value?.product_family.maturation_sensitive === 'SENSITIVE')
const isQualityTypeUsed = computed(() => selectedRawProduct.value?.product_policy?.quality_source === 'SOURCING')
const shouldDisplayReceivementPackageInfo = computed(() => (
    selectedRawProduct.value
    && !(
        (isQualityTypeUsed.value && !buyEvent.qualityTypeId)
        || (isMaturationTypeUsed.value && !buyEvent.maturationTypeId)
    )
))

const populateRawSku = async (rawSkus: RawSku[] | null) => {
    rawSkuData.value = rawSkus?.find(i =>
        i.id_maturation_type === buyEvent.maturationTypeId
        && i.id_quality_type === buyEvent.qualityTypeId,
    ) || null
    isRawSkuDataLoading.value = false
    buyEvent.wholesaleQuantity = rawSkuData.value?.inward_lot?.packaging_quantity || 1
}

watchEffect(async () => {
    if (
        !buyEvent.productId
    ) {
        rawSkuData.value = null
        buyEvent.wholesaleQuantity = 1
        return
    }

    isRawSkuDataLoading.value = true
    populateRawSku(await getFeasibleRawSkusWithPackaging(
        selectedRawProduct.value?.id_raw_product || '',
        buyEvent.qualityTypeId,
        buyEvent.maturationTypeId,
        (shopper.value?.id_location || ''),
    ))
})

const showQuantityAlert = computed(() => {
    const total_quantity = buyEvent.measureType === MeasureType.Box ? buyEvent.wholesaleQuantity * buyEvent.quantity : buyEvent.quantity
    return total_quantity % (rawSkuData.value?.inward_lot?.packaging_quantity || 1) !== 0
})

const itemMeasureOption = computed(() => {
    const wholesaleMeasureType = rawSkuData.value?.inward_lot
        ? rawSkuData.value?.inward_lot?.packaging_unit
        : selectedRawProduct.value?.quantity_wholesale_unit
    buyEvent.wholesaleQuantityUnit = wholesaleMeasureType ?? ''
    return wholesaleMeasureType
        ? {
            label: wholesaleMeasureType === 'KG' ? 'Granel' : 'Unidade',
            value: wholesaleMeasureType === 'KG' ? MeasureType.Weight : MeasureType.Unit,
        }
        : null
})

const measureOptions = computed(() => [
    { label: 'Atacado', value: MeasureType.Box },
    itemMeasureOption.value ?? null,
].filter(Boolean))

const priceOptions = computed<LabeledRadioOption[]>(() => [
    { label: 'Preço por Embalagem', value: PriceType.UnitPrice },
    { label: 'Preço Total', value: PriceType.TotalPrice },
])


const isTotalQuantityNotMultiple = computed(() => {
    const total_quantity = buyEvent.measureType === MeasureType.Box ? buyEvent.wholesaleQuantity * buyEvent.quantity : buyEvent.quantity

    return total_quantity % (rawSkuData?.value?.inward_lot?.packaging_quantity || 1) !== 0
})

function MMC(num1: number, num2: number) {
    let resto, a, b
    a = num1
    b = num2
    do {
        resto = a % b
        a = b
        b = resto
    } while (resto !== 0)
    return (num1 * num2) / a
}

const getNotWholesaleRecommendedBuyQuantity = () => {
    const quantity = buyEvent.quantity
    const inwardLot = rawSkuData?.value?.inward_lot
    const inwardUnit = inwardLot?.packaging_unit
    const inwardQuantity = inwardLot?.packaging_quantity || 1

    return `Compra Recomendada: ${(Math.ceil(quantity / inwardQuantity)) * inwardQuantity} ${inwardUnit}`
}

const getWholesaleRecommendedBuyQuantity = () => {
    const quantity = buyEvent.quantity
    const inwardLot = rawSkuData?.value?.inward_lot
    const inwardQuantity = inwardLot?.packaging_quantity || 1
    const wholesale = buyEvent.wholesaleQuantity

    const mmc = MMC(inwardQuantity, wholesale)
    const boxesMultiplier = mmc / wholesale

    return `Compra Recomendada: ${Math.ceil((quantity * wholesale) / mmc) * boxesMultiplier} Caixas`
}

const getRecommendedPurchaseValue = () => {
    const recommendedIfNotWholesale = getNotWholesaleRecommendedBuyQuantity()
    const recommendedIfWholesale = getWholesaleRecommendedBuyQuantity()
    const isWholesale = buyEvent.measureType === MeasureType.Box
    return isWholesale ? recommendedIfWholesale : recommendedIfNotWholesale
}

const getErrorNotMultipleQuantityMessage = (measure_type: string) => {
    return `Essa compra (${buyEvent.measureType === 'box' ? buyEvent.quantity * buyEvent.wholesaleQuantity : buyEvent.quantity}
                        ${itemMeasureOption ? (measure_type === MeasureType.Weight ? 'kg' : 'un') : ''}) não satisfaz as condições de
                        recebimento.`
}

const getOkQuantityMessage = (measure_type: string) => {
    return `Essa compra (${buyEvent.measureType === 'box' ? buyEvent.quantity * buyEvent.wholesaleQuantity : buyEvent.quantity}
                        ${itemMeasureOption ? (measure_type === MeasureType.Weight ? 'kg' : 'un') : ''}) satisfaz as condições de
                        recebimento.`
}

/**
 * We need to create a procurement item on
 * - The shopper's location
 * - With the shopper's ID
 * - With the currently selected Raw Product ID
 * - With the provided inputs for the weight or units bought
 *
 * After the creation of the procurement item, we need to create
 * the procurement purchase event with the provided inputs for
 * price, quantity and provider.
 */
const onSubmit = async () => {
    validate({
        ignore: [
            buyEvent.measureType !== MeasureType.Box && 'wholesaleQuantity',
        ].filter(Boolean) as string[],
    })
    console.log(errors)
    if (!isValid.value) {
        return
    }
    isLoadingProductCreation.value = true
    try {
        const procurementItem = await addProcurementItem({
            buyEvent: unref(buyEvent),
            locationId: shopper.value?.id_location || '',
            shopperId: String(shopper.value?.id_shopper),
            productId: buyEvent.productId,
            referenceDate: referenceDate.value,
            maturationTypeId: buyEvent.maturationTypeId,
            qualityTypeId: buyEvent.qualityTypeId,
        })
        await addBuyEvent(procurementItem?.id_procurement_item || '', unref(buyEvent))
        const getProcurementItemsFetchFilter = () => {
            const filter: APIFilter = {
                to_be_purchased_at: { eq: referenceDate.value },
            }
            if (!isFeatureSet('disable-shopper-filter')) {
                filter.id_shopper = { eq: String(shopper.value?.id_shopper) }
            }
            else if (shopper.value?.id_location) {
                filter.id_location = { eq: String(shopper.value?.id_location) }
            }
            return filter
        }
        await listProcurementItems({
            filter: getProcurementItemsFetchFilter(),
            empty: true,
            with_inward_lot: true,
        })
        isLoadingProductCreation.value = false
        success('Item comprado adicionado!', 'Você já pode ver o item na lista de itens comprados.')
        emit('collapse')
        closeModal()
    }
    catch (e: any) {
        if (e.status === 409) {
            warning('Item repetido!', 'Já existe uma compra para esse item nesse produtor hoje.')
        }
        else {
            warning('Erro desconhecido!', 'Entre em contato com o suporte no canal do slack #suporte')
        }
        isLoadingProductCreation.value = false
    }
}

const onCloseModal = () => {
    emit('collapse')
    closeModal()
}

const priceLabel = computed(() =>
    buyEvent.measureType === MeasureType.Weight
        ? 'Preço por Kg'
        : 'Preço por Embalagem',
)
</script>

<template>
    <div text-left relative>
        <div text-textcolor-900 font-700 sticky top-0 bg-white z-20 pt-2 pb-4 flex items-center>
            <div>
                Detalhes da compra
            </div>
            <div ml-auto text-5 @click="onCloseModal">
                <div i-carbon-close />
            </div>
        </div>
        <div>
            <div>
                <div my-2px text-textcolor-700 text-3>
                    Nome do produto
                </div>
                <div>
                    <cc-raw-product-select
                        v-model="buyEvent.productId"
                        :error="errors.productId"
                        label="Produto"
                        placeholder="Selecione um produto"
                        data-test-id="add-new-item-form-product-select"
                        @selected="onRawProductSelected"
                    />
                </div>
            </div>

            <div v-if="selectedRawProduct">
                <cc-quality-type-select
                    v-if="isQualityTypeUsed"
                    v-model="buyEvent.qualityTypeId"
                    :error="errors.qualityTypeId"
                    :disabled="!isQualityTypeUsed"
                />

                <cc-maturation-type-select
                    v-if="isMaturationTypeUsed"
                    v-model="buyEvent.maturationTypeId"
                    :disabled="!isMaturationTypeUsed"
                    :label="!isMaturationTypeUsed ? 'Produto não é sensível a maturação' : 'Maturação'"
                    :error="errors.maturationTypeId"
                />
                <div v-if="shouldDisplayReceivementPackageInfo" mt-1 px-3 py-1 flex text-3 items-center rounded-2 border-1 border-primary-100>
                    <div text-3 text-textcolor-500 text-center mr-auto>
                        Pacote de recebimento:
                    </div>
                    <div text-3 text-textcolor-500 ml-auto>
                        {{
                            !isRawSkuDataLoading
                                ? rawSkuData?.inward_lot
                                    ? `${rawSkuData?.inward_lot.packaging_quantity} ${rawSkuData?.inward_lot.packaging_unit}`
                                    : 'Indisponível'
                                : 'Carregando..'
                        }}
                    </div>
                </div>
            </div>

            <labeled-radio
                v-model="buyEvent.measureType"
                :options="measureOptions"
                label="Tipo de medida"
            />
            <div>
                <div my-1 text-textcolor-700 text-3>
                    Nome do fornecedor
                </div>
                <cc-provider-select
                    v-model="buyEvent.provider"
                    :error="errors.provider"
                    data-test-id="add-new-item-form-provider-select"
                    :ableToShop="true"
                />
            </div>
            <numbered-input
                v-if="buyEvent.measureType === 'box'"
                v-model="buyEvent.wholesaleQuantity"
                :error="errors.wholesaleQuantity"
                label="Peso da Embalagem (Caixa ou Saco)"
                label-icon="i-mdi-package-variant-closed"
                :unit="itemMeasureOption
                    ? (
                        itemMeasureOption.value === MeasureType.Weight
                            ? 'kg'
                            : (`unidade${buyEvent.wholesaleQuantity !== 1 ? 's' : ''}`))
                    : ''"
                :allow-decimals="itemMeasureOption ? itemMeasureOption.value === MeasureType.Weight : true"
                :increments="[]"
                data-test-id="add-new-item-form-wholesale-quantity-input"
                positive-only
                mt-2
            />
            <div flex items-center my-1 class="visible">
                <div v-if="isTotalQuantityNotMultiple && !buyEvent.isJustInTime" class="i-mdi-alert" text-warning mr-1 />
                <div mr-1 :class="[isTotalQuantityNotMultiple ? 'text-warning' : 'text-green']">
                    <div text-3>
                        {{ !buyEvent.isJustInTime ? isTotalQuantityNotMultiple 
                            ? getErrorNotMultipleQuantityMessage(itemMeasureOption ? itemMeasureOption.value : 'unit')
                            : getOkQuantityMessage(itemMeasureOption ? itemMeasureOption.value : 'unit') : null }}
                    </div>
                </div>
            </div>
            <numbered-input
                v-model="buyEvent.quantity"
                :error="errors.quantity"
                label="Quantidade"
                label-icon="i-ic:outline-add-to-photos"
                :allow-decimals="buyEvent.measureType === MeasureType.Weight"
                data-test-id="add-new-item-form-quantity-input"
                positive-only
                mt-2
            />
            <div :class="!showQuantityAlert ? 'invisible' : 'visible'" flex items-center text-primary my-1>
                <div text-3>
                    {{ getRecommendedPurchaseValue() }}
                </div>
            </div>
            <labeled-radio
                v-model="buyEvent.priceType"
                :options="priceOptions"
                label-icon="i-ic-outline-monetization-on"
                label="Tipos de precificação"
            />
            <numbered-input
                v-model="buyEvent.price"
                :error="errors.price"
                positive-only
                label="Preço"
                label-icon="i-ic-outline-monetization-on"
                data-test-id="add-new-item-form-price-input"
                currency
                mt-2
            />
            <div border-1 my-2 w-full border-primary-100 flex items-center />
            <div>
                <div px-3 mb-3 flex items-center w-fit @click="buyEvent.isJustInTime = !buyEvent.isJustInTime">
                    <div mr-1>
                        <div
                            border-1 rounded-1 border-primary-300
                            ml-1 w-4 h-4 flex items-center
                        >
                            {{ !buyEvent.isJustInTime ? '✓' : '' }}
                        </div>
                    </div>
                    <div text-3.5>
                        Compra direto para o estoque
                    </div>
                </div>
                <cc-button
                    w-full data-test-id="add-new-item-form-submit"
                    :disabled="(isTotalQuantityNotMultiple && !buyEvent.isJustInTime) || !rawSkuData?.inward_lot"
                    @click="onSubmit"
                >
                    <div v-if="isLoadingProductCreation" mr-1 i-eos-icons-loading />
                    {{ isLoadingProductCreation ? 'Adicionando produto...' : 'Adicionar produto' }}
                </cc-button>
            </div>
        </div>
    </div>
</template>
